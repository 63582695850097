/** @jsx jsx **/
import { jsx, Container } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '~/components/layout'

import HeadingV2 from '~/componentsV2/heading'

import { space } from '~/gatsby-plugin-theme-ui'

type CataloguePageType = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        description: string
        heading: string
      }
    }
  }
}

const CatalogPage = () => {
  const query = useStaticQuery<CataloguePageType>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "catalog" } }) {
        frontmatter {
          headerVariant
          header {
            title
            heading
            description
          }
        }
      }
    }
  `)

  const { header } = query.pageData.frontmatter

  return (
    <Layout title={header.title} description={header.description} headerVariant="default">
      <Container
        sx={{
          my: [space.sm1, null],
        }}
      >
        <HeadingV2
          sx={{
            mb: 20,
            mt: 40,
          }}
          level="1"
        >
          {header.heading}
        </HeadingV2>
        <div
          style={{
            position: 'relative',
            paddingTop: 'max(60%,326px)',
            marginBottom: '32px',
            height: '0',
            width: '100%',
          }}
        >
          <iframe
            title={header.title}
            sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
            allowFullScreen={true}
            style={{
              position: 'absolute',
              border: 'none',
              width: '100%',
              height: '100%',
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
            }}
            src="https://e.issuu.com/embed.html?d=catalog2023_final_pages_hi_res&u=bensoleimani"
          />
        </div>
      </Container>
    </Layout>
  )
}

export default CatalogPage
